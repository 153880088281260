const api = {
    GET_DATA: (scene) => {
        return `${process.env.VUE_APP_API_BASE_URL}/scene/${scene}`;
    },
    GET_DATA_DETAIL: (sceneId, number) => {
        return `${process.env.VUE_APP_API_BASE_URL}/scene/${sceneId}/content/${number}`;
    },
    UPDATE_DATA_THUMBNAIL: (sceneId, number) => {
        return `${process.env.VUE_APP_API_BASE_URL}/scene/${sceneId}/content/${number}/thumbnail`;
    },
    UPDATE_DATA: (sceneId, number) => {
        return `${process.env.VUE_APP_API_BASE_URL}/scene/${sceneId}/content/${number}`;
    },
    UPDATE_DOCENT: (sceneId) => {
        return `${process.env.VUE_APP_API_BASE_URL}/scene/${sceneId}/docent`;
    },
    GET_ALL_LINK: () => {
        return `${process.env.VUE_APP_API_BASE_URL}/scene/external-link/list`;
    },
    POST_LINK: () => {
        return `${process.env.VUE_APP_API_BASE_URL}/scene/external-link`;
    },
    UPDATE_LINK: (linkId) => {
        return `${process.env.VUE_APP_API_BASE_URL}/scene/external-link/${linkId}`;
    },
    DELETE_LINK: (linkId) => {
        return `${process.env.VUE_APP_API_BASE_URL}/scene/external-link/${linkId}`;
    },
    GET_QUESTLOG: (page, limit=10) => {
        return `${process.env.VUE_APP_API_BASE_URL}/scene/quest-log/list?page=${page}&limit=${limit}`;
    },
    DELETE_QUESTLOG: (questLogId) => {
        return `${process.env.VUE_APP_API_BASE_URL}/scene/quest-log/${questLogId}`;
    },
    LOGIN: () => {
        return `${process.env.VUE_APP_API_BASE_URL}/auth/login`
    },
    LOGOUT: () => {
        return `${process.env.VUE_APP_API_BASE_URL}/auth/logout`
    },
    GET_USER_INFO: () => {
        return `${process.env.VUE_APP_API_BASE_URL}/auth`
    },
    UPDATE_USER_PW: () => {
        return `${process.env.VUE_APP_API_BASE_URL}/auth`
    },
    GET_CONFERENCE_ALERT: () => {
        return `${process.env.VUE_APP_API_BASE_URL}/scene/alert`
    },
    UPDATE_CONFERENCE_ALERT: () => {
        return `${process.env.VUE_APP_API_BASE_URL}/scene/alert`
    },
}

export default api;