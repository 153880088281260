import Vue from 'vue';
import Vuex from 'vuex';

import auth from "./modules/auth";
import alerts from "./modules/alerts-module";
import profile from "./modules/profile-module";
import users from "./modules/users-module";
import AuthStore from '@/store/auth/AuthStore';
import persistedstate from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth: AuthStore,
    alerts,
    profile,
    users,
    // userInfo: AuthStore
  },
  plugins: [
      persistedstate({
          path:["AuthStore"]
      })
  ]
});
